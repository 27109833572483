import './App.css'
import { React, useEffect, useState } from 'react'
import GridItem from './GridItem'

const apiPrefix = window.location.pathname

function App () {
  const [data, setData] = useState([])
  const [analytics, setAnalytics] = useState({})

  useEffect(() => {
    async function fetchData () {
      const response = await fetch(`${apiPrefix}api/get-rates`)
      const { rates, analytics } = await response.json()
      setData(rates)
      setAnalytics(analytics)
    }
    fetchData()
  }, [])

  return (
    <div className="App">
      <div className="grid-container">
        {data.length
          ? data.map(item =>
            (<GridItem data={item} analytics={analytics} key={item.merchant}/>)
          )
          : (<GridItem data={{}} analytics={{}} key="loading" />)}
      </div>
    </div>
  )
}

export default App
