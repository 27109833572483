/* eslint-disable react/prop-types */
import React, { Fragment } from 'react'
import SplitFlapDisplay, { ALPHA, NUMERIC } from 'react-split-flap-display'

const FONT_SIZE = '1em'
const CHARACTER_WIDTH = '1em'
const MERCHANT_WIDTH = 11

const TEXTCOLOR = {
  RED: '#ff0000',
  GREEN: '#00ff00',
  WHITE: '#ffffff',
  YELLOW: '#d7c22a'
}

const getTextColor = ({ merchant, rateName, analytics, defaultColor = TEXTCOLOR.WHITE }) => {
  const rateHigh = `${rateName}High`
  const rateLow = `${rateName}Low`

  const highs = analytics[rateHigh]
  const lows = analytics[rateLow]

  if (highs?.merchants.includes(merchant)) {
    return TEXTCOLOR.GREEN
  }

  if (lows?.merchants.includes(merchant)) {
    return TEXTCOLOR.RED
  }
  return defaultColor
}

const GridItem = ({ data, analytics }) => {
  return (
    <Fragment>
        <div className='grid-item'>
        <SplitFlapDisplay
            characterSet={[
              ...ALPHA, ...NUMERIC
            ]}
            characterWidth={CHARACTER_WIDTH}
            value={data?.merchant?.padEnd(MERCHANT_WIDTH) || ('LOADING').padEnd(MERCHANT_WIDTH)}
            fontSize={FONT_SIZE}
            borderColor="rgb(30, 30, 33)"
            background="rgb(34, 34, 34)"
            textColor={TEXTCOLOR.YELLOW}
        />
        </div>
        <div className='grid-item'></div>
        <div className='grid-item'>
        <SplitFlapDisplay
            characterSet={[
              ...NUMERIC, '.', '%', ' ', 'M'
            ]}
            characterWidth={CHARACTER_WIDTH}
            value={data?.tweleveMonthRate || '12M   0.00%'}
            fontSize={FONT_SIZE}
            borderColor="rgb(30, 30, 33)"
            background="rgb(34, 34, 34)"
            textColor= {getTextColor({ merchant: data?.merchant, analytics, rateName: 'tweleveMonth' })}// {TEXTCOLOR.RED} // "#ffffff"
        />
        </div>
        <div className='grid-item'>
        <SplitFlapDisplay
            characterSet={[
              ...NUMERIC, '.', '%', ' ', 'M'
            ]}
            characterWidth={CHARACTER_WIDTH}
            value={data?.eighteenMonthRate || '18M   0.00%'}
            fontSize={FONT_SIZE}
            borderColor="rgb(30, 30, 33)"
            background="rgb(34, 34, 34)"
            className='grid-item'
            textColor={getTextColor({ merchant: data?.merchant, analytics, rateName: 'eighteenMonth' })}
        />
        </div>
        <div className='grid-item'>
        <SplitFlapDisplay
            characterSet={[
              ...NUMERIC, '.', '%', ' ', 'M'
            ]}
            characterWidth={CHARACTER_WIDTH}
            value={data?.twentyFourMonthRate || '24M   0.00%'}
            fontSize={FONT_SIZE}
            borderColor="rgb(30, 30, 33)"
            background="rgb(34, 34, 34)"
            className='grid-item'
            textColor={getTextColor({ merchant: data?.merchant, analytics, rateName: 'twentyFourMonth' })}
            />
        </div>
        <div className='grid-item'>
        <SplitFlapDisplay
            characterSet={[
              ...NUMERIC, '.', '%', ' ', 'M'
            ]}
            characterWidth={CHARACTER_WIDTH}
            value={data?.thirtySixMonthRate || '36M   0.00%'}
            fontSize={FONT_SIZE}
            borderColor="rgb(30, 30, 33)"
            background="rgb(34, 34, 34)"
            textColor={getTextColor({ merchant: data?.merchant, analytics, rateName: 'thirtySixMonth' })}
        />
        </div>
        <div className='grid-item'>
        <SplitFlapDisplay
            characterSet={[
              ...NUMERIC, '.', '%', ' ', 'M'
            ]}
            characterWidth={CHARACTER_WIDTH}
            value={data?.fortyEightMonthRate || '48M   0.00%'}
            fontSize={FONT_SIZE}
            borderColor="rgb(30, 30, 33)"
            background="rgb(34, 34, 34)"
            textColor={getTextColor({ merchant: data?.merchant, analytics, rateName: 'fortyEightMonth' })}
        />
        </div>
        <div className='grid-item'>
        <SplitFlapDisplay
            characterSet={[
              ...NUMERIC, '.', '%', ' ', 'M'
            ]}
            characterWidth={CHARACTER_WIDTH}
            value={data?.sixtyMonthRate || '60M   0.00%'}
            fontSize={FONT_SIZE}
            borderColor="rgb(30, 30, 33)"
            background="rgb(34, 34, 34)"
            textColor={getTextColor({ merchant: data?.merchant, analytics, rateName: 'sixtyMonth' })}
        />
        </div>
    </Fragment>
  )
}

export default GridItem
